import React from 'react';
import autobind from 'auto-bind';
import { withRouter } from 'react-router-dom';
import { Tabs, Badge } from 'antd4';

import {
  Agency,
  Contacts,
  Dropout,
  IRS,
  Leases,
  Main,
  Notifications,
  Payments,
  ServicePayments,
  Services,
  Uploads
} from './sections';

import './SingleClient.scss';

const { TabPane } = Tabs;

const tabIndexes = {
  json: 0,
  main: 1,
  notification: 2,
  agency: 3,
  service: 4,
  contact: 5,
  uploads: 6,
  dropout: 7,
  service_payments: 8,
  payments: 9,
  lease: 10,
  irs: 11
};
class SingleClient extends React.Component {
  constructor(props) {
    super(props);

    autobind(this);
  }

  async updateField(path, value) {
    const targetId = this.props.data?._id;

    if (this.props.type === SingleClient.TYPES.descendant) {
      return await this.props.updateField(
        path,
        value,
        this.props.parent._id,
        targetId
      );
    } else {
      return await this.props.updateField(path, value, targetId);
    }
  }

  async addArrayElement(arrayPath, value = null) {
    const targetId = this.props.data?._id;

    if (this.props.type === SingleClient.TYPES.descendant) {
      console.log(
        'singleClient add array to relative:',
        arrayPath,
        this.props.parent._id,
        targetId
      );
      return await this.props.addArrayElement(
        arrayPath,
        this.props.parent._id,
        targetId,
        value
      );
    } else {
      console.log('singleClient add array to client:', arrayPath, targetId);
      return await this.props.addArrayElement(arrayPath, targetId, null, value);
    }
  }

  async deleteArrayElement(arrayPath, oid) {
    const targetId = this.props.data?._id;

    if (this.props.type === SingleClient.TYPES.descendant) {
      return await this.props.deleteArrayElement(
        arrayPath,
        oid,
        this.props.parent._id,
        targetId
      );
    } else {
      return await this.props.deleteArrayElement(
        arrayPath,
        oid,
        this.props.parent._id
      );
    }
  }

  render() {
    const { location, history } = this.props;

    const showExtras =
      this.props.data?.isMainInvestor ||
      (this.props.data?.isRelative &&
        this.props.data?.main?.familiar_com_faturacao);

    const notifications = this.props.data?.['notification'] ?? [];
    const notificationCount = notifications.filter(x => x?.read !== true)
      .length;

    return (
      <Tabs
        id="single-client"
        defaultActiveKey={this.props.tab ?? '0'}
        onChange={activeKey => {
          history.replace(location.pathname + '?tab=' + activeKey);
        }}
        type="card"
        size="small"
      >
        <TabPane tab="JSON" key="0">
          <pre>{JSON.stringify(this.props.data, null, 2)}</pre>
        </TabPane>
        {this.props.data?.['main'] && (
          <TabPane key={tabIndexes.main} tab={<span>Principais</span>}>
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['main']}
                data={this.props.data}
              ></FieldRenderer> */}
              <Main
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                data={this.props.data}
              ></Main>
            </div>
          </TabPane>
        )}
        {showExtras && this.props.data?.['notification'] && (
          <TabPane
            key={tabIndexes.notification}
            tab={
              <span>
                <span className="pr-2">Avisos</span>
                <Badge count={notificationCount}></Badge>
              </span>
            }
          >
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['notification']}
                data={this.props.data}
              ></FieldRenderer> */}
              <Notifications
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                data={this.props.data}
              ></Notifications>
            </div>
          </TabPane>
        )}
        {this.props.data?.['agency'] && (
          <TabPane key={tabIndexes.agency} tab={<span>Agência</span>}>
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['agency']}
                data={this.props.data}
              ></FieldRenderer> */}
              <Agency
                updateField={this.updateField}
                data={this.props.data}
              ></Agency>
            </div>
          </TabPane>
        )}
        {showExtras && this.props.data?.['service'] && (
          <TabPane key={tabIndexes.service} tab={<span>Serviços</span>}>
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['service']}
                data={this.props.data}
              ></FieldRenderer> */}
              <Services
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                data={this.props.data}
              ></Services>
            </div>
          </TabPane>
        )}
        {showExtras && this.props.data?.['contact'] && (
          <TabPane key={tabIndexes.contact} tab={<span>Contacto</span>}>
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['contact']}
                data={this.props.data}
              ></FieldRenderer> */}
              <Contacts
                updateField={this.updateField}
                data={this.props.data}
              ></Contacts>
            </div>
          </TabPane>
        )}
        {this.props.data?.['uploads'] && (
          <TabPane key={tabIndexes.uploads} tab={<span>Uploads</span>}>
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['uploads']}
                data={this.props.data}
              ></FieldRenderer> */}
              <Uploads
                updateField={this.updateField}
                label="Uploads"
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                data={this.props.data}
              ></Uploads>
            </div>
          </TabPane>
        )}
        {this.props.data?.['dropout'] && (
          <TabPane key={tabIndexes.dropout} tab={<span>Desistência</span>}>
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['dropout']}
                data={this.props.data}
              ></FieldRenderer> */}
              <Dropout
                updateField={this.updateField}
                data={this.props.data}
              ></Dropout>
            </div>
          </TabPane>
        )}
        {showExtras && this.props.data?.['service_payments'] && (
          <TabPane
            key={tabIndexes.service_payments}
            tab={<span>Pag. Serviço</span>}
          >
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['service_payments']}
                data={this.props.data}
              ></FieldRenderer> */}
              <ServicePayments
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                data={this.props.data}
              ></ServicePayments>
            </div>
          </TabPane>
        )}
        Serviço
        {showExtras && this.props.data?.['payments'] && (
          <TabPane key={tabIndexes.payments} tab={<span>Pagamentos</span>}>
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['payments']}
                data={this.props.data}
              ></FieldRenderer> */}
              <Payments
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                data={this.props.data}
              ></Payments>
            </div>
          </TabPane>
        )}
        {showExtras && this.props.data?.['lease'] && (
          <TabPane key={tabIndexes.lease} tab={<span>Rendas</span>}>
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['lease']}
                data={this.props.data}
              ></FieldRenderer> */}
              <Leases
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                data={this.props.data}
              ></Leases>
            </div>
          </TabPane>
        )}
        {showExtras && this.props.data?.['irs'] && (
          <TabPane key={tabIndexes.irs} tab={<span>IRS</span>}>
            <div className="fields-container">
              {/* <FieldRenderer
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                field={FIELDS['irs']}
                data={this.props.data}
              ></FieldRenderer> */}
              <IRS
                updateField={this.updateField}
                addArrayElement={this.addArrayElement}
                deleteArrayElement={this.deleteArrayElement}
                data={this.props.data}
              ></IRS>
            </div>
          </TabPane>
        )}
      </Tabs>
    );
  }
}

SingleClient.TYPES = { descendant: 'descendant', parent: 'parent' };

export default withRouter(SingleClient);
