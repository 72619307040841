import { Storage } from 'aws-amplify';
import jsf from 'json-schema-faker';
import uuidv4 from 'uuid/v4';

import { processSchema } from '../data/processSchema.js';
import dataSchema from '../data/data.schema.json';
import { address } from '../../../common/variables';
import { handleResponse, authenticate } from './common';

/* Real Data setup */

const folderName = 'id/';

/* Fake Data setup */

jsf.option({
  ignoreMissingRefs: true,
  failOnInvalidTypes: false,
  failOnInvalidFormat: false,
  alwaysFakeOptionals: true
});
const { schema } = processSchema(dataSchema.properties);

const schemaCopy = JSON.parse(JSON.stringify(dataSchema));
schemaCopy.properties = schema;

const numItems = 50;

/* Fake Requests */

const getClientsMock = async (num = numItems) => {
  const data = [];
  for (let i = 0; i < num; i++) {
    const item = await jsf.resolve(schemaCopy);
    data.push(item);
  }

  await new Promise(r => setTimeout(r, 800));

  return {
    ok: true,
    data: data,
    hasData: true
  };
};

const postClientMock = async client => {
  console.log(
    `POST /id/client, body: {  ${JSON.stringify(client, null, 2)}}} `
  );
  await new Promise(r => setTimeout(r, 800));
  const fakeResponse = await getClientsMock(1);
  const fakeClient = fakeResponse.data?.[0];
  return {
    ok: true,
    data: {
      ...fakeClient,
      ...client,
      _id: '' + Math.floor(Math.random() * 1000000000)
    },
    hasData: true
  };
};

const postRelativeMock = async relative => {
  console.log(
    `POST /id/relative, body: {  ${JSON.stringify(relative, null, 2)}}} `
  );
  await new Promise(r => setTimeout(r, 800));
  return {
    ok: true,
    data: {
      ...relative,
      _id: '' + Math.floor(Math.random() * 1000000000)
    },
    hasData: true
  };
};

const deleteClientMock = async clientId => {
  console.log(` DELETE /id/client/${clientId} `);
  await new Promise(r => setTimeout(r, 800));
  return {
    ok: true,
    data: { _id: clientId },
    hasData: true
  };
};

const deleteRelativeMock = async relativeId => {
  console.log(` DELETE /id/relative/${relativeId} `);
  await new Promise(r => setTimeout(r, 800));
  return {
    ok: true,
    data: { _id: relativeId },
    hasData: true
  };
};
const putClientMock = async (clientId, path, value) => {
  console.log(
    ` PUT /id/client/${clientId}, body: { path: ${path}, value: ${value}}} `
  );
  await new Promise(r => setTimeout(r, 800));
  return {
    ok: true,
    data: value,
    hasData: true
  };
};

const putRelativeMock = async (relativeId, path, value) => {
  console.log(
    ` PUT /id/relative/${relativeId}, body: { path: ${path}, value: ${value}}} `
  );
  await new Promise(r => setTimeout(r, 800));
  return {
    ok: true,
    data: value,
    hasData: true
  };
};

const postArrayElementClientMock = async (path, clientId) => {
  console.log(` POST /id/client/${clientId}/array, body: { path: ${path}}} `);
  await new Promise(r => setTimeout(r, 800));
  return {
    ok: true,
    data: {},
    hasData: true
  };
};

const postArrayElementRelativeMock = async (path, relativeId) => {
  console.log(
    ` POST /id/relative/${relativeId}/array, body: { path: ${path}}} `
  );
  await new Promise(r => setTimeout(r, 800));
  return {
    ok: true,
    data: {},
    hasData: true
  };
};

const deleteArrayElementClientMock = async (path, index, clientId) => {
  console.log(` DELETE /id/client/${clientId}/array, body: { path: ${path}}} `);
  await new Promise(r => setTimeout(r, 800));
  return {
    ok: true,
    data: {},
    hasData: true
  };
};

const deleteArrayElementRelativeMock = async (path, index, relativeId) => {
  console.log(
    ` DELETE /id/relative/${relativeId}/array, body: { path: ${path}}} `
  );
  await new Promise(r => setTimeout(r, 800));
  return {
    ok: true,
    data: {},
    hasData: true
  };
};

const uploadFileMock = async file => {
  const random = uuidv4();
  const fileID = random + file.name;

  let s3ResultMock = { key: fileID };

  return { ok: true, data: s3ResultMock };
};

const getFileLinkMock = async key => {
  return 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf';
};

const removeFileMock = async s3Key => {
  return { ok: true, data: null };
};

/* True requests section */

const BE_ADDR = address;

const getFullClients = async () => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'id/client/full', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};

const getClients = async () => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'id/client', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};

const getClient = async _id => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/client/${_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};

const postClient = async client => {
  console.log(
    `POST /id/client, body: {  ${JSON.stringify(client, null, 2)}}} `
  );
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'id/client', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(client)
  });
  return await handleResponse(response);
};

const postRelative = async relative => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'id/relative', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(relative)
  });
  return await handleResponse(response);
};

const deleteClient = async clientId => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/client/${clientId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};

const deleteRelative = async relativeId => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/relative/${relativeId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
};
const putClient = async (clientId, path, value) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/client/${clientId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, value })
  });
  return await handleResponse(response);
};

const putRelative = async (relativeId, path, value) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/relative/${relativeId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, value })
  });
  return await handleResponse(response);
};

const postArrayElementClient = async (path, clientId, value) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/client/${clientId}/array`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, value: value ?? {} })
  });

  return await handleResponse(response);
};

const postArrayElementRelative = async (path, relativeId, value) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/relative/${relativeId}/array`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, value: value ?? {} })
  });
  return await handleResponse(response);
};

const deleteArrayElementClient = async (path, _id, clientId) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/client/${clientId}/array`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, _id })
  });
  return await handleResponse(response);
};

const deleteArrayElementRelative = async (path, _id, relativeId) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `id/relative/${relativeId}/array`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ path, _id })
  });
  return await handleResponse(response);
};

const uploadFile = async file => {
  const random = uuidv4();
  const fileID = random + '-' + file.name;

  const customPrefix = {
    public: folderName
  };

  let s3Result;
  try {
    s3Result = await Storage.put(fileID, file, {
      contentType: file.type,
      customPrefix: customPrefix
    });
  } catch (error) {
    return { ok: false, data: s3Result };
  }

  return { ok: true, data: s3Result };
};

const removeFile = async s3Key => {
  const customPrefix = {
    public: folderName
  };

  let s3Result;
  try {
    s3Result = await Storage.remove(s3Key, {
      customPrefix: customPrefix
    });
  } catch (error) {
    return { ok: false, data: s3Result };
  }

  return { ok: true, data: s3Result };
};

const getFileLink = async key => {
  const customPrefix = {
    public: folderName
    // protected: 'protected/',
    // private: 'private/'
  };
  const result = await Storage.get(key, {
    customPrefix: customPrefix,
    expires: 600
  });
  console.log('fileLink: ', result);

  return result;
};

const routes =
  process.env.REACT_APP_FAKE_ID_DATA === 'true'
    ? {
        getClients: getClientsMock,
        postClient: postClientMock,
        postRelative: postRelativeMock,
        deleteClient: deleteClientMock,
        deleteRelative: deleteRelativeMock,
        putClient: putClientMock,
        putRelative: putRelativeMock,
        postArrayElementClient: postArrayElementClientMock,
        postArrayElementRelative: postArrayElementRelativeMock,
        deleteArrayElementClient: deleteArrayElementClientMock,
        deleteArrayElementRelative: deleteArrayElementRelativeMock,
        uploadFile: uploadFileMock,
        getFileLink: getFileLinkMock,
        removeFile: removeFileMock
      }
    : {
        getFullClients,
        getClients,
        getClient,
        postClient,
        postRelative,
        deleteClient,
        deleteRelative,
        putClient,
        putRelative,
        postArrayElementClient,
        postArrayElementRelative,
        deleteArrayElementClient,
        deleteArrayElementRelative,
        uploadFile,
        getFileLink,
        removeFile
      };

export default routes;
