import React, { Fragment } from 'react';
import autobind from 'auto-bind';
import { Button, Popover, Select } from 'antd4';
import {
  Field,
  DateField,
  TextAreaField,
  CheckboxField,
  UploadField,
  Hr,
  Placeholder,
  FieldNationality,
  SelectField
} from '../../../fields';

import '../../../fields/Fields.scss';
import { ClockCircleOutlined } from '@ant-design/icons';

class Main extends React.Component {
  constructor(props) {
    super(props);

    autobind(this);
  }

  render() {
    return (
      <Fragment key={'main'}>
        <h4 className="w-100">Dados Principais</h4>
        <Field
          key={'role'}
          width={33}
          label={'Relação c/ Principal'}
          path={'main.role'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <CheckboxField
          key={'familiar_com_faturacao'}
          label={'Tem faturação própria'}
          width={33}
          path={'main.familiar_com_faturacao'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></CheckboxField>
        <Field
          key={'applicant'}
          width={50}
          label={'Requente'}
          path={'main.applicant'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <Field
          key={'nome'}
          width={50}
          label={
            /* INSERIR componente <History/> AQUI - isto é só para testes */
            <div className="flex items-center gap-2">
              <span>Nome</span>
              {/* <Popover
                className="mr-1"
                content={
                  <div className=" flex flex-col p-0 border border-gray-500">
                    <div className="flex items-center h-8 px-2 border-b border-gray-500 bg-gray-100">
                      <div className="px-2 border-r">User</div>
                      <div className="px-2 border-r">Valor anterior</div>
                      <div className="px-2 border-r">Valor actual</div>
                      <div className="px-2 border-r">Data</div>
                    </div>
                    <div className="flex items-center h-8 border-b border-gray-500 bg-gray-100">
                      <div className="px-2 border-r">User</div>
                      <div className="px-2 border-r">Valor anterior</div>
                      <div className="px-2 border-r">Valor actual</div>
                      <div className="px-2 border-r">Data</div>
                    </div>
                    <div className="flex items-center h-8 px-2 border-b border-gray-500 bg-gray-100">
                      <div className="px-2 border-r">User</div>
                      <div className="px-2 border-r">Valor anterior</div>
                      <div className="px-2 border-r">Valor actual</div>
                      <div className="px-2 border-r">Data</div>
                    </div>
                    <div className="flex items-center h-8 px-2 border-b border-gray-500 bg-gray-100">
                      <div className="px-2 border-r">User</div>
                      <div className="px-2 border-r">Valor anterior</div>
                      <div className="px-2 border-r">Valor actual</div>
                      <div className="px-2 border-r">Data</div>
                    </div>
                  </div>
                }
                title="Filtrar"
                trigger="click"
              >
                <ClockCircleOutlined className="w-8 h-8"></ClockCircleOutlined>
              </Popover> */}
            </div>
          }
          path={'main.nome'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <SelectField
          key={'genero'}
          width={50}
          label={'Género'}
          mode="multiple"
          path={'main.genero'}
          multiple={false}
          data={this.props.data}
          updateField={this.props.updateField}
          options={[
            { label: 'Feminino', value: 'Feminino' },
            {
              label: 'Masculino',
              value: 'Masculino'
            }
          ]}
        ></SelectField>
        <Field
          key={'dossier'}
          label={'Dossier'}
          path={'main.dossier'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <Field
          key={'juris'}
          label={'Juris'}
          path={'main.juris'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <SelectField
          key={'situacao'}
          width={50}
          label={'Situação'}
          mode="multiple"
          path={'main.situacao'}
          multiple={false}
          data={this.props.data}
          updateField={this.props.updateField}
          options={[
            { label: 'Ativo', value: 'Ativo' },
            {
              label: 'Ativo — Regime Simplificado',
              value: 'Ativo — Regime Simplificado'
            },
            {
              label: 'Ativo — Contabilidade Organizada',
              value: 'Ativo — Contabilidade Organizada'
            },
            { label: 'Pack Resident', value: 'Pack Resident' },
            {
              label: 'Desistente — Alteração Representante Fiscal',
              value: 'Desistente — Alteração Representante Fiscal'
            },
            {
              label: 'Desistente — Alteração Morada Fiscal (R)',
              value: 'Desistente — Alteração Morada Fiscal (R)'
            },
            {
              label: 'Desistente — Processo de Golden Visa',
              value: 'Desistente — Processo de Golden Visa'
            },
            {
              label: 'Desistente — Notificação Eletrónica',
              value: 'Desistente — Notificação Eletrónica'
            },
            {
              label: 'Notificação Eletrónica Pedida',
              value: 'Notificação Eletrónica Pedida'
            },
            { label: 'Pedido de NIF', value: 'Pedido de NIF' },
            {
              label: 'Carta Registada — Rescisão do contrato (Imparidade)',
              value: 'Carta Registada — Rescisão do contrato (Imparidade)'
            },
            {
              label: 'Carta Registada — Rescisão do contrato (Injunção)',
              value: 'Carta Registada — Rescisão do contrato (Injunção)'
            },
            {
              label: 'Processo de Injunção em curso',
              value: 'Processo de Injunção em curso'
            },
            {
              label: 'Processo de Perda por Imparidade em curso',
              value: 'Processo de Perda por Imparidade em curso'
            },
            { label: 'Falecido', value: 'Falecido' }
          ]}
        ></SelectField>
        <SelectField
          multiple={true}
          key={'investment_type'}
          width={50}
          label={'Tipos de Investimento'}
          options={[
            { label: 'Imóveis', value: 'imoveis' },
            { label: 'Fundos', value: 'fundos' },
            { label: 'N/A', value: 'N/A' }
          ]}
          path={`main.investment_type`}
          data={this.props.data}
          updateField={this.props.updateField}
        ></SelectField>
        <TextAreaField
          key={'observacao_geral'}
          width={100}
          label={'Obs. Geral'}
          minRows={2}
          path={'main.observacao_geral'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></TextAreaField>
        <Hr width={100}></Hr>
        <Field
          key={'nif'}
          width={33}
          label={'NIF'}
          path={'main.nif'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <DateField
          showTime={false}
          key={'nif_data_pedido'}
          label={'Data pedido'}
          width={33}
          path={'main.nif_data_pedido'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></DateField>
        <DateField
          showTime={false}
          key={'nif_data_recebido'}
          label={'Data Recebido'}
          width={33}
          path={'main.nif_data_recebido'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></DateField>
        <UploadField
          key={'nif_document'}
          label={'Doc. NIF'}
          width={66}
          path={'main.nif_document'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></UploadField>
        <Placeholder width={33}></Placeholder>
        <Field
          key={'password'}
          width={33}
          label={'Password (AT)'}
          path={'main.password'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <DateField
          showTime={false}
          key={'data_pedido_password'}
          label={'Data Pedido'}
          width={33}
          path={'main.data_pedido_password'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></DateField>
        <Hr width={100}></Hr>
        <Field
          key={'passaporte'}
          width={50}
          label={'Passaporte'}
          path={'main.passaporte'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        {/* <Field
          key={'nacionalidade'}
          width={50}
          label={'Nacionalidade'}
          path={'main.nacionalidade'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field> */}
        <FieldNationality
          key={'nacionalidade'}
          width={50}
          label={'Nacionalidade'}
          path={'main.nacionalidade'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></FieldNationality>
      </Fragment>
    );
  }

  onChange(value) {
    console.log(`selected ${value}`);
  }

  onSearch(val) {
    console.log('search:', val);
  }
}

export default Main;
